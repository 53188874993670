<template>
  <div class="footer">
    <div class="me-content">
      <div>
        <img src="../../assets/mobileImg/componentsImg/my.png" alt="" />
        <span>联系我们</span>
      </div>
      <span v-html="aboutContent" class="aboutTxt"></span>
      <span @click="goAbout">《查看更多》</span>
    </div>
    <div class="me-link">
      <div>
        <img src="../../assets/mobileImg/componentsImg/link.png" alt="" />
        <span>友情链接</span>
      </div>
      <div class="link-item">
        <span
          v-for="(item, index) in itemObj.links"
          :key="index"
          @click="goLink(item.linkurl)"
          >{{ item.name }}</span
        >
      </div>
    </div>
    <div class="me-phone">
      <div>
        <img src="../../assets/mobileImg/componentsImg/phone.png" alt="" />
        <span>联系方式</span>
      </div>
      <div>
        <div class="number">
          <div class="num-left">
            <span>热线: {{ itemObj.home_zxrx }}</span>
            <span>邮箱: {{ itemObj.home_email }}</span>
            <span>地址: {{ itemObj.home_address }}</span>
            <!-- <span>转载说明: {{ itemObj.transferNotice }}</span> -->
          </div>
          <div class="num-right">
            <span>手机: {{ itemObj.home_phone }}</span>
            <span>传真: {{ itemObj.home_cz }}</span>
            <!-- <span>版权说明: {{ itemObj.copyRightNotice }} </span> -->
          </div>
        </div>
        <!-- <div class="coder">
          <div>
            <img :src="BaseUrl + itemObj.home_fotewmont" alt="" />
            <span>资本中原</span>
          </div>
          <div>
            <img :src="BaseUrl + itemObj.home_fotewmtwo" alt="" />
            <span>资本中原</span>
          </div>
        </div> -->
        <div class="beian">
          <a href="https://beian.miit.gov.cn" target="_blank"
            >备案信息: {{ itemObj.beian }}</a
          >
          <div>豫公网安备 {{ itemObj.home_gongan }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      itemObj: {},
      aboutContent: "",
    };
  },
  created() {
    this.getData();
    this.getAbout();
  },
  methods: {
    //    获取底部信息
    getData() {
      this.$http({
        url: "/index/setList",
      }).then((res) => {
        this.itemObj = res.data;
      });
    },
    //获取关于我们信息
    getAbout() {
      this.$http({
        url: "/about/index",
      }).then((res) => {
        this.aboutContent = res.data.content;
      });
    },
    goAbout() {
      this.$router.push("/m_about");
    },
    goLink(i) {
      window.location.href = i;
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 750px;
  padding-top: 38px;
  padding-bottom: 30px;
  background-color: #12407d;
  .me-content {
    width: 690px;
    margin: 0 auto 36px;
    color: #ffffff;
    > div:nth-child(1) {
      display: flex;
      align-items: center;
      width: 690px;
      padding-bottom: 23px;
      margin-bottom: 27px;
      font-size: 26px;
      border-bottom: 1px solid #fff;
      > img:nth-child(1) {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
    /deep/ .aboutTxt {
      height: 100px;
      // margin-bottom: 30px;
      > p {
        display: block;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    > span:nth-child(2),
    > span:nth-child(3) {
      display: block;
      width: 676px;
      font-size: 20px;
    }
  }
  .me-link {
    width: 690px;
    margin: 0 auto;
    color: #fff;
    > div:nth-child(1) {
      display: flex;
      align-items: center;
      width: 690px;
      padding-bottom: 23px;
      margin-bottom: 10px;
      font-size: 26px;
      border-bottom: 1px solid #fff;
      > img:nth-child(1) {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
    .link-item {
      display: flex;
      flex-wrap: wrap;
      font-size: 20px;
      > span {
        padding: 10px 3px;
      }
    }
  }
  .me-phone {
    width: 690px;
    margin: 50px auto;
    color: #fff;
    > div:nth-child(1) {
      display: flex;
      align-items: center;
      width: 690px;
      padding-bottom: 23px;
      margin-bottom: 27px;
      font-size: 26px;
      border-bottom: 1px solid #fff;
      > img:nth-child(1) {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
    > div:nth-child(2) {
      font-size: 20px;
      color: #fff;
      .number {
        display: flex;
        // margin-bottom: 5px;
        > div {
          display: flex;
          flex-direction: column;
          width: 400px;
          overflow: hidden;
          > span {
            margin-bottom: 10px;
          }
        }
        > div:nth-child(2) {
          width: 290px !important;
          margin-left: 10px;
        }
      }
      .coder {
        display: flex;
        > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-right: 55px;
          > img {
            width: 100px;
            height: 100px;
            margin-bottom: 10px;
          }
        }
      }
      .beian {
        > a {
          display: block;
          text-decoration: none;
          color: #fff;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
