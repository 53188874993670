<template>
  <div class="header">
    <div class="header-top">
      <img src="../../assets/mobileImg/componentsImg/logo.png" alt="" />
      <div class="header-search">
        <input type="text" placeholder="请输入内容" v-model="value" />
        <img
          src="../../assets/mobileImg/componentsImg/search.png"
          alt=""
          @click="goSearch"
        />
      </div>
    </div>
    <div class="header-nav">
      <span v-for="(item, index) in nav" :key="index" @click="goPath(index)">
        {{ item.name }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      nav: [
        "首页",
        "资本热点",
        "资本人物",
        "资本动态",
        "资本影视",
        "乡村振兴",
        "健康生活",
        "投诉维权",
      ],
      value: "",
    };
  },
  created() {
    this.getData();
    let value =this.$route.query.searchVal;
    if(value){
        this.value = value
    }
    
  },
  methods: {
    getData() {
      this.$http({
        url: "/index/setList",
      }).then((res) => {
        this.nav = res.data.menu;
      });
    },
    goPath(i) {
      if (i == 0) {
        this.$router.push("/m_index");
      } else if (i == 1) {
        this.$router.push("/m_hot");
      } else if (i == 2) {
        this.$router.push("/m_figure");
      } else if (i == 3) {
        this.$router.push("/m_dynamic");
      } else if (i == 4) {
        this.$router.push("/m_video");
      } else if (i == 5) {
        this.$router.push("/m_rural");
      } else if (i == 6) {
        this.$router.push("/m_healthylife");
      } else if (i == 7) {
        this.$router.push("/m_complaints");
      }
    },
    goSearch() {
      if (this.value) {
        this.$emit('getlist',this.value)
        this.$router.push({
          path: "/m_search",
          query: {
            searchVal: this.value,
          },
        });
      } else {
        this.$message({
          message: "请输入要搜索的内容",
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang='less' scoped>
.header {
  width: 750px;
  .header-top {
    width: 750px;
    padding: 26px 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    // overflow: hidden;
    > img:nth-child(1) {
      width: 128px;
      height: 40px;
      vertical-align: middle;
      margin-right: 332px;
    }
    .header-search {
      display: flex;
      align-items: center;
      width: 230px;
      height: 40px;
      border: 1px solid #eeeeee;
      > input:nth-child(1) {
        width: 180px;
        border: none;
        outline: none;
        text-indent: 0.5em;
        font-size: 21px;
      }
      > img:nth-child(2) {
        width: 25px;
        height: 25px;
      }
    }
  }
  .header-nav {
    display: flex;
    flex-wrap: wrap;
    width: 750px;
    height: 100px;
    padding: 18px 30px;
    box-sizing: border-box;
    background-color: #eaeef4;
    > span {
      width: 135px;
      height: 40px;
      // text-align: center;
      font-size: 24px;
      color: #333333;
    }
  }
}
</style>